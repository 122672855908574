body {
  background-color: #000000;
  font-family: "Swisot";
  font-weight: 100;
}
.dotDotDot {
  cursor: pointer;
  width: 300px;
  height: 300px;
  border: 0px solid #dadada;
}
.btnMinus {
  border: none;
  background-color: inherit;
  padding: 14px 28px;
  font-size: 110px;
  font-family: "Swisot";
  font-weight: 100;
  height: 60;
  cursor: pointer;
  display: inline-block;
}
.btnMinus:focus {
  outline: none;
  box-shadow: none;
}
.btnPlus {
  border: none;
  background-color: inherit;
  padding: 14px 28px;
  font-size: 60px;
  font-family: "Swisot";
  font-weight: 100;
  height: 60;
  cursor: pointer;
  display: inline-block;
}
.custom-btn {
  /* background: #fff; */
  color: "#dadada";
  border: 2px solid "#dadada";
  border-radius: 3px;
  padding: 5px 10px;
  text-transform: uppercase;
}

.btnPlus:focus {
  outline: none;
  box-shadow: none;
}
.main-text {
  font-family: "Swisot";
  font-size: 500px;
}
.color-nav {
  background-color: black;
}
@font-face {
  font-family: "Swisot";
  src: url("./resources/swisot1.ttf");
}

.fader {
  -webkit-animation: fadein 1s linear 1 normal forwards;
}

@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.navbar-brand > img {
  padding: 7px 14px;
}
.token {
  width: 1080px;
  height: 1080px;
  border-radius: 50%;
  transform: scale(0.2);
  object-fit: contain;
}
